import {TextField, Button} from '@mui/material'
import React from 'react'

export interface ContactProps  {
  setShow: any,
}

const Contact = ({setShow} : ContactProps) => {
  return (
    <form accept-charset="UTF-8" action="https://www.formbackend.com/f/2fa44d7190934950" method="POST">
      <TextField type="text" style={{margin: "10px", textAlign: "center"}} label="Full Name" id="name" name="name" required />
      <TextField type="email" style={{margin: "10px", textAlign: "center"}} label="Email" id="email" name="email" required />
      <TextField type="text" style={{margin: "10px", textAlign: "center"}} multiline rows={3} label="Message"  id="message" name="message" required />
      <Button style={{paddingTop: '10px', backgroundColor:'#a32720', color: 'white', borderColor: '#a32720'}} variant='outlined'  type="submit">Submit</Button>
      <Button style={{marginLeft: '10px', paddingTop: '10px',  color: 'black', borderColor: '#a32720'}} variant='outlined' onClick={() => setShow(false)}  >Cancel</Button>
    </form>
  )
}

export default Contact
