import React from 'react';
import MyMap from './components/Map';
import '@vis.gl/react-google-maps/examples.css';

function App() {

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <MyMap />
    </div>
  );
}

export default App;
