import {Button} from '@mui/base';
import * as React from 'react';
import Contact from './Contact';

function ControlPanel() {
  const [showContact, setShowContact] = React.useState<boolean>(false)

  return (
    <div className="control-panel">
      <img src='logo192.png' style={{maxWidth: '50px'}} />
      <h3>DGtek Pty Ltd</h3>
      <p>
        Greenfields map
      </p>
      <div className="links">
        <a
          href="https://dgtek.net"
          target="_new">
          https://dgtek.net
        </a>

        {!showContact && (
          <Button onClick={() => setShowContact(true)}>
            Become an RSP
          </Button>)}
      </div>
      <br />
      <div>
        {showContact && <Contact setShow={setShowContact} />}
      </div>
    </div>
  );
}

export default React.memo(ControlPanel);
